<template>
  <div class="from_outer">
    <div>
      <el-form ref="formName" label-width="120px" :model="formLabelAlign">
        <el-form-item
          label="名称："
          prop="name"
          :rules="[{ required: true, message: '请选择是否显示' }]"
        >
          <el-input
            placeholder="请输入名称"
            size="mini"
            class="form-inp"
            v-model="formLabelAlign.name"
          ></el-input>
        </el-form-item>

        <el-form-item
          label="今日状态："
          prop="status"
          :rules="[{ required: true, message: '请选择今日状态' }]"
        >
          <el-radio
            v-for="item in options"
            v-model="formLabelAlign.status"
            :key="item.id"
            :label="item.id"
            >{{ item.name }}</el-radio
          >
        </el-form-item>

        <el-form-item
          label="简述："
          prop="desc"
          :rules="[{ required: true, message: '请输入简述' }]"
        >
          <el-input
            type="textarea"
            placeholder="请输入简述"
            size="mini"
            class="form-inp"
            v-model="formLabelAlign.desc"
          ></el-input>
        </el-form-item>

        <el-form-item label="上传图片：">
          <uploadingimg v-model="formLabelAlign.img_List" />
        </el-form-item>

        <el-form-item label="上传视频：">
          <uploadingVideo v-model="formLabelAlign.video_List" />
        </el-form-item>

        <!-- <el-form-item label="上传录音：">
          <el-upload
            class="upload-demo"
            ref="upload"
            :action="this.$store.state.uploadImage"
            :headers="this.$tools.config()"
            :auto-upload="false"
          >
            <el-button slot="trigger" size="small" type="primary"
              >选取文件</el-button
            >
          </el-upload>
        </el-form-item> -->
      </el-form>

      <div style="text-align: center; margin: 20px 0">
        <el-button @click="on_preserve('formName')" type="primary"
          >保存</el-button
        >
        <el-button @click="route_go">返回</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { dutylogsaveInfo, dutylogsave } from "../../assets/request/api";
import uploadingimg from "../uploading/uploading_img.vue";
import uploadingVideo from "../../components/uploading/uploading_Video.vue";

export default {
  name: "duty_logbookredact",
  props: ["options"],
  components: {
    uploadingimg,
    uploadingVideo,
  },
  data() {
    return {
      show_list: [],
      powers_List: "",
      formLabelAlign: {
        name: "",
        desc: "",
        status: "",
        img_List: [],
        video_List: [],
      },

      disabled: false,
    };
  },
  methods: {
    way_show(id) {
      console.log(this.options);
      if (id) this.formLabelAlign.id = id;
      dutylogsaveInfo({ id }).then((res) => {
        let { show_list, info, powers } = res.data;
        this.powers_List = powers;
        this.show_list = show_list;
        if (id) {
          let { formLabelAlign } = this;
          let { name, status, desc } = info;
          formLabelAlign.name = name;
          formLabelAlign.desc = desc;
          formLabelAlign.status = status;
          if (info.vedio) {
            formLabelAlign.video_List = info.vedio.split(",");
          }
          if (info.images) {
            formLabelAlign.img_List = info.images.split(",");
          }
        }
      });
    },

    //点击保存
    on_preserve(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let formLabelAlign = JSON.parse(JSON.stringify(this.formLabelAlign));
          formLabelAlign.vedio = formLabelAlign.video_List.join(",");
          formLabelAlign.images = formLabelAlign.img_List.join(",");
          delete formLabelAlign.video_List;
          delete formLabelAlign.img_List;
          dutylogsave(formLabelAlign).then((res) => {
            if (res.code == 0) {
              this.$message({
                message: res.msg,
                type: "success",
              });
              let that = this;
              that.$emit("way_tableData");
              setTimeout(function () {
                that.route_go();
              }, 1000);
            }
          });
        } else {
          return false;
        }
      });
    },
    handleAvatarSuccess(res, file) {
      console.log(res);
      console.log(this.img_List);
      this.img_List.push(res.data.url);
    },
    //上传限制
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 20;
      const isJPG =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/jpg" ||
        file.type === "image/bmp";

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG ，png ，jpg ，bmp格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 20MB!");
      }
      return isJPG && isLt2M;
    },
    handleRemove(file) {
      console.log(file);
    },
    //返回
    route_go() {
      this.$emit("update:refbool", false);
    },
  },
};
</script>

<style lang="less" scoped>
.from {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
}
.form-inp {
  width: 300px;
}
.pop-inp {
  width: 150px;
}
.tag-sty {
  width: 300px;
  display: flex;
  flex-direction: column;
}
.selectlength {
  color: #515a6e;
  border: 1px solid #abdcff;
  background-color: #f0faff;
  padding: 10px 20px;
  font-size: 14px;
  margin: 20px 0;
  border: 5px;
  span {
    color: rgb(45, 140, 240);
  }
}
.search_button {
  display: flex;
  margin-bottom: 18px;
  flex-direction: column-reverse;
}
.botton-an {
  text-align: center;
  margin-top: 10px;
}
.pop-img {
  margin: 0 5px 10px 0;
  width: 40px;
  height: 40px;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 80px;
  height: 80px;
  display: block;
}
/deep/ .el-upload--picture-card {
  width: 80px;
  height: 80px;
  line-height: 80px;
}
</style>